import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormikContext } from 'formik';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const PasswordField = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { touched, errors, handleChange, handleBlur, values } = useFormikContext();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      name="password"
      type={showPassword ? 'text' : 'password'}
      label="Contraseña"
      variant="filled"
      fullWidth
      value={values.password}
      onChange={handleChange}
      onBlur={handleBlur}
      error={touched.password && !!errors.password}
      helperText={touched.password && errors.password ? errors.password : ' '}
      InputProps={{
        sx: {
          border: '1px solid #ccc',
          backgroundColor: '#f9f9f9',
          '&:hover': { backgroundColor: '#F9F9F9' },
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowPassword} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      FormHelperTextProps={{ sx: { minHeight: 24 } }}
    />
  );
};

export default PasswordField;
